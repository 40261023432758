html{
  @media (max-width:575px) {
    font-size: 14px;
  }
}

/* Windows */
@font-face {
  font-family: YuGothicM;
  font-weight: normal;
  src: local('YuGothic-Medium'),  /* Mediumを明示的に指定 */
       local('Yu Gothic Medium'), /* Chrome用 */
       local('YuGothic-Regular'); /* Windows8.1ではMediumがないのでRegularを指定 */
}
@font-face {
  font-family: YuGothicM;
  font-weight: bold;
  src: local('YoGothic-Bold'), /* Boldはそのまま */
       local('Yu Gothic');     /* Chrome用 */
}

body{
  color: #444;
  // 背景微移動
  background: url('/_assets/imgs/bg.jpg') fixed 50% 0 / cover;
  width: 100%;
}

body.jp{
  font-family:BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
}

body.tw{
  font-family:"Microsoft Yahei","PingHei" ,sans-serif;
}

body.cn{
  font-family:"Microsoft Yahei","PingHei" ,sans-serif;
}


body.ko{
  font-family:"Malgun Gothic","Yoon Gothic",sans-serif;
}


a{
  color: #333;
  img {
    -webkit-transition: all .5s;
    transition: all .5s;
    opacity: 1;
  }
  &:hover{
    color: #333;
    img{
      opacity: .6
    }
  }
}
.link-fill {
  border: 1px solid #444;
  background: #444;
  padding: .9rem 2rem;
  color: #fff !important;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s;
  &:hover {
    background: #fff;
    color: #444 !important;
  }
  i {
    font-size: 120%;
    padding-right: .3rem
  }
}
.link-border {
  border: 1px solid #444;
  padding: .9rem 2rem;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  color:#444 !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s;
  &:hover {
    background: #444;
    color: #fff !important;
  }
  i {
    font-size: 120%;
    padding-left: .3rem
  }
}
.link-border-w{
  border-color: #fff;
  color: #fff !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s;
  &:hover{
    background: #fff;
    color: #444 !important;
  }
  i {
    font-size: 120%;
    padding-left: .3rem
  }
}
.to-page-top {
  z-index: 110;
  position: fixed;
  right:0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  a {
    text-decoration: none;
    display: block;
    font-size: 1.9rem;
    text-align: center;
    padding: 8px;
    color: #555;
  }
}

#headbar{
  z-index: 2000;
  position:absolute;
  background: #068cd2;
  color: #fff;
  width: 100%;
  min-height: 30px;
  @media (min-width:768px) {
    top: 0;
    .marquee{
      display: none;
    }
    p{
      font-size: .9rem;
      padding: .2rem;
      margin-bottom: 0;
    }
  }
  @media (max-width:767px) {
    top: 30px;
    .marquee{
      height: 30px;
      padding: .3rem;
      div{
        font-size: .9rem;
      }
    }
    p{
      display: none;
    }
  }
}
#nav-lang{
  z-index: 10000;
  position:absolute;
  margin: 0;
  padding: 0;
  li{
    a{
      margin: 0 .25rem .4rem;
      color: #444;
      padding: 0 .1rem .1rem;
      &:hover{
        text-decoration: none;
      }
    }
  }
  @media (min-width:768px) {
    top: 50px;
    right: 12rem;
    li{
      font-size: .9rem;
      a{
        border-bottom:1px solid #fff;
        &:hover{
          border-bottom-color: #444;
        }
      }
    }
  }
  @media (max-width:767px) {
    top: 0;
    width: 100%;
    text-align: center;
    background: #000;
    height: 30px;
    li{
      font-size: .75rem;
      a{
        color: #fff;
        padding: 6px 5px 0;
        display: inline-block;
        border-bottom:1px solid #000;
        &:hover{
          border-bottom-color: #fff;
        }
      }
    }
  }
}

body.jp #lang-jp, body.en #lang-en, body.tw #lang-tw, body.cn #lang-cn, body.ko #lang-ko{
  @media (min-width:768px) {
    border-bottom-color: #444;
  }
}

.navbar{
  position:absolute;
  z-index: 1000;
  width: 100vw;
  top:30px;
  background: #fff;
  box-shadow: 1px 0 10px rgba(0,0,0,.1);
  @media (max-width:480px){
    padding: .5rem;
  }
  .navbar-brand{
    @media (min-width:1200px) {
      img{
        height: 84px;
      }
    }
    @media (max-width:1199px){
      img{
        height:70px;
      }
    }
    @media (max-width:767px) {
      display: block;
      margin: 0 auto;
      padding-right: 50px;
      max-width: 400px;
      img{
        width: 100%;
        height: auto;
      }
    }
    @media (max-width:480px) {
      max-width: 320px;
    }
    @media (max-width:400px) {
      max-width: 220px;
    }
  }
  @media (max-width:767px) {
    top:60px;
    flex-direction:row-reverse;
  }
}
#navbars{
  align-items:flex-end;
  flex-direction:column-reverse;
  @media (min-width:768px) {
    .navbar-nav{
      display: flex;
      flex-wrap:wrap;
      justify-content:flex-end;
      @media (max-width:1129px){
        font-size: 90%;
      }
    }
  }
  a{
    color: #068cd2;
    @media (min-width:768px) {
      padding: .5rem 0 .1rem;
      margin: 0 .5rem .4rem;
    }
    border-bottom: 1px solid #fff;
    &:hover{
      border-bottom-color: #068cd2;
    }
  }
  li.dropdown a:hover{
    border-color: #fff;
  }
  .show .dropdown-menu{
    display: block;
    right:0;
    left:auto;
    overflow: hidden;
    min-width: 8rem;
    padding: 0;margin:0;
    a{
      text-align: center;
      margin-left: 0;
      display: block;
    }
  }
  li a.link-fill{
    background: #e34024;
    color:#fff;
    font-size: .8rem;
    padding: 5px 10px 7px;
    border-color: #e34024;
    margin-top:3px;
    &:hover {
      background: #fff;
      color: #e34024 !important;
      text-decoration: none;
    }
  }
}
.home #nav-top, body.about #nav-about, body.program #nav-program, body.stay #nav-stay, body.safety #nav-safety, body.mikatagoko #nav-mikatagoko, body.faq #nav-faq{
  border-bottom: 1px solid #068cd2;
}

footer{
  background: #444;
  color: #fff;
  position: relative;
  z-index: 100;
  #askme{
      @media (min-width:768px) {
        background: #078dd4;
        .container{
          background: url('/uploads/footer-contact-bg.jpg') no-repeat center bottom / cover;
          padding-bottom: 45vw !important;
          @media (min-width:920px) {
            padding-bottom: 39vw !important;
          }
          @media (min-width:1200px) {
            padding-bottom: 24vw !important;
          }
        }
      }
      @media (max-width:767px) {
        background: #078dd4 url('/uploads/footer-contact-bg.jpg') no-repeat center bottom / contain;
          padding-bottom: 67.22vw !important;
      }
      @media (max-width:575px) {
      }
    p.target span{
      color: #666;
      background: #fff;
      padding:.5rem .8rem;
      margin:.3rem;
      border-radius: 5px;
      display: inline-block;
    }
    p{
      span, a{
        margin:.3rem;
      }
    }
  }
  #social{
    .fb-container{
      position: relative;
      padding-bottom:100%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  #contact{
    p{
      line-height: 1.8;
      a{
        color: #fff;
        display: inline-block;
        margin-right: 2rem;
        padding:0;
        margin-bottom: .8rem;
      }
    }
    h2{
      line-height: 1.5;
    }
  }
  #letsgowakasa{
    a{
      background: #fff;
      display: inline-block;
    }
  }
}

.bg-top {
background: url(/_assets/imgs/bg.jpg) ;
background-size: cover;
}

.bg-bottom {
background: url(/_assets/imgs/bg.jpg) bottom ;
background-size: cover;
}

#headline{
  position: relative;
  z-index: 100;
  @media (min-width:768px) {
    padding-bottom:50px;
  }
}
.slick-arrow{
  z-index: 1000;
  color: #fff;
  top: 40%;
  &::before{
    font-family: FontAwesome;
    font-size:3.1rem;
  }
}
.slick-prev{
  left:10px;
  &::before{
    left:0;
    content: '\f104';
  }
}
.slick-next{
  right:10px;
  &::before{
    left:0;
    content: '\f105';
  }
}

@media (max-width:767px) {
  .pt-5, .py-5{
    padding-top: 2rem !important
  }
}

.about-head{
  background: url('/uploads/about-head-bg.png') no-repeat center bottom;
  @media (max-width:768px) {
    background-size: 100%;
    p{
      br{
        display: none;
      }
    }
  }
}
.contents{
  a{
    color:#068cd2;
    text-decoration: underline;
  }
  .box{
    background: rgba(255, 255, 255, 0.8);
  }
  h2 a{
    color: #444;
    text-decoration: none;
  }
}

.downloads{
  border: 2px solid #e34024;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9);
  h2{
    color: #e34024;
  }
  ul{
    padding-left: 0;
    li{
      list-style: none;
      a{
        text-decoration: underline;
        color:#068cd2;
      }
    }
  }
}


/* home */
.home{
  background: #fff;
  #feature{
    position: relative;
    #feature-pc{
      height: 100vh;
      position: fixed !important;
      z-index: -100;
    }
    #feature-sp{
      background: #fff;
      position: absolute;
      z-index: 100;
      width: 100vw;
    }
    #badge{
      width: 100vw;
      position: absolute;
      img{
        display: block;
        position: absolute;
        top:230px;
        right: 40px;
        z-index: 101;
        width: 270px;
      }
      @media (min-width:768px) {
        height: 100vh;
      }
      @media (max-width:767px) {
        height: 136.3vw;
        img{
          top: 40px;
          right: 15px;
          width: 160px;
        }
      }
    }
    @media (max-width:767px) {
      height: 136.3vw;
    }
  }
  .contents{
    position: relative;
    z-index: 100;
    @media (min-width:768px) {
      padding-top: 100vh;
    }
    // &> div{
    //   background: #fff;
    // }
    .pickup img{
      width: 100%;
    }
    #about{
      background: url(/_assets/imgs/bg.jpg) top / cover;
    }
    #program{
      background: #fff;
      .pickup{
        width: 25%;
      }
      .slick-list{
        a{
          display: block;
          color: #444;
          text-decoration: none;
          @media (max-width:768px) {
            font-size: .85rem;
          }
        }
      }
    }
    #mikatagoko{
      background: url(/uploads/home-mikatagoko-bg.jpg) no-repeat / cover;
      color: #fff;
    	position: relative;
      &::before{
        background-color: rgba(0,0,0,0.5);
        position: absolute;
      	top: 0;
      	right: 0;
      	bottom: 0;
      	left: 0;
      	content: ' ';
      }
      .cover-bg{
        position: absolute;
        width: 100%;
        text-align: center;
      }
    }
  }
  #downloads{
    position: relative;
    z-index: 100;
    background: url(/_assets/imgs/bg.jpg) bottom / cover;
  }
}
/* about */
body.about{
  #headline{
    padding-bottom: 30px;
    #headline-image{
      background: url('/uploads/about-headline.jpg') no-repeat center top/ cover;
      height:47.361vw;
    }
  }
  p.lead{
    font-size: 1.5rem;
  }
  #point{
    h2{
      color:#e34024;
      font-size: 3rem;
    }
    img{
      margin: 0 auto;
      display: block;
      border-radius: 3px;
    }
  }
  #history{
    .award{
      background: #e34024;
      color: #fff;
      .medal{
        align-self: center;
      }
    }
    ul{
      padding-left: 0;
      li{
        list-style: none;
        a{
          text-decoration: underline;
        }
      }
    }
  }
  #staff{
    img{
      width: 100%;
    }
  }
}
.headline{
  background-repeat:no-repeat;
  color: #111;
  box-sizing: border-box;
  padding-bottom: 20px;
  h1{
    border-bottom: 7px solid #333;
    font-weight: bold;
    padding:.3rem 1rem;
    display: inline-block;
    margin:12vw 0 5rem;
    text-shadow: 0 0 5px #fff,0 0 5px #fff,0 0 5px #fff,0 0 5px #fff, 0 0 3px #fff;
  }
  p{
    span{
      background: rgba(255, 255, 255, 1);
      padding: .1rem;
    }
  }
  @media (min-width:768px) {
    background-position: center bottom;
    background-size: cover;
    height:47.361vw;
  }
  @media (max-width:767px) {
    background-position: center top;
    background-size: contain;
    h1{
      margin:25vw 0 35vw;
      font-size: 1.6rem;
    }
  }
}
#headline.headline-mini{
  height: auto;
  background: url('/uploads/page-head-bg.jpg') no-repeat center center / cover;
  @media (min-width:768px) {
    padding: 50px 0 70px;
    margin-bottom: 3rem;
  }
  @media (max-width:767px) {
    padding: 30px 0 40px;
    margin-bottom: 2rem;
  }
  h1{
    margin: 0;
  }
  p{
    margin-top: 2rem;
  }
}
/* program */
.program{
  #headline{
    background-image:url('/uploads/program-head-bg.jpg');
  }
  #program-index{
    img{
      width: 100%;
    }
    h3{
      span{
        border-radius: 5px;
        vertical-align: middle;
        color: #fff;
        display: inline-block;
        &.num{
          padding:.1rem 0;
          font-size: 1rem;
          width: 1.6rem;
          text-align: center;
          margin-right: .3rem;
        }
        &.num1{
          background: #369;
        }
        &.num2{
          background: #659833;
        }
        &.num3{
          background: #7A4C1E;
        }
        &.num4{
          background: #DF7C19;
        }
        &.pickup{
          padding:.2rem .3rem;
          margin-left: .5rem;
          font-size: .8rem;
          background: #e34024;
        }
      }
    }
    p{
      span.note{
        font-size: .9rem;
      }
    }
    p.thanks{
      font-size: .8rem;
      text-align: right;
      color: #666;
    //   position: absolute;
    //   top: 44%;
    //   right: 20px;
    //   color: #fff;
    //   text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    }
  }
  #plan{
    table{
      width: 100%;
      background: #fff;
      font-size: 95%;
      th{
        font-weight: normal;
        width: 4.2rem;
        text-align: right;
        padding:.15rem .8rem .3rem 0;
      }
      td{
        padding:.15rem .5rem .3rem;
      }
    }
  }
}

/* stay */
.stay{
  #headline{
    background-image:url('/uploads/stay-head-bg.jpg');
  }
  .link-border {
    i {
        padding: 0;
    }
  }
  #yado-index{
    h2{
      color: #e34024;
    }
  }
  .yado{
    p{
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    table{
      th{
        padding: .75rem 0;
        width:4rem;
        font-size:.9rem;
      }
      td{
        padding: .75rem 0 .75rem .5rem;
        font-size:.9rem;
      }
    }
  }
}

/* faq */
.faq{
  dt{
    font-size: 1.6rem;
    cursor: pointer;
  }
  dd{
    display: none;
    p{
      padding:.8rem 1rem;
      margin-top:.5rem;
      border:1px dashed #666;
      font-size: .9rem;
    }
  }
}

/* mikatagoko */
.mikatagoko{
  #headline{
    background-image:url('/uploads/mikatagoko-head-bg.jpg');
    @media (min-width:768px) {
      padding-top: 4.5vw;
    }
  }
  #access{
    p{
      .note{
        font-size: .8rem;
        display: inline-block;
        margin-bottom: .3rem;
      }
    }
  }
  #gmap{
    width: 100%;
    height: 100%;
    padding-bottom:80%;
    max-width: 100%;
  }

}

/* contact */
.contact{
  #flow{
    .arrow{
      font-size: 2rem;
      color:#ccc;
    }
  }
}


/* 英語 */
.en{
  #nav-lang{
    @media (min-width:768px) {
      right: 14rem;
    }
    @media (min-width:768px) and (max-width:1266px) {
      margin-top: -5px;
    }
  }
  #headbar{
    @media (min-width:768px) {
      height: auto;
      min-height: 30px;
      p{
        font-size: .7rem;
        padding: .4rem .2rem 0;
      }
    }
    @media (max-width:767px) {
      .marquee{
        padding-top: .5rem;
        div{
          font-size: .7rem;
        }
      }
    }
  }
  #navbars{
    @media (min-width:768px) {
      .navbar-nav{
        li{
          font-size: .8rem;
        }
      }
      .navbar-nav:nth-child(1){
        a{
          padding: .5rem 0 0;
          margin: 0 .5rem;
        }
      }
    }
  }
}

/* tw */
.tw{
  #nav-lang{
    @media (min-width:768px) {
      right: 9rem;
    }
  }
}
/* cn */
.cn{
  #nav-lang{
    @media (min-width:768px) {
      right: 8rem;
    }
  }
}
/* ko */
.ko{
  #nav-lang{
    @media (min-width:768px) {
      right: 8rem;
    }
  }
}
