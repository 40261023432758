@charset "UTF-8";
@media (max-width: 575px) {
  html {
    font-size: 14px; } }

/* Windows */
@font-face {
  font-family: YuGothicM;
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular");
  /* Windows8.1ではMediumがないのでRegularを指定 */ }

@font-face {
  font-family: YuGothicM;
  font-weight: bold;
  src: local("YoGothic-Bold"), local("Yu Gothic");
  /* Chrome用 */ }

body {
  color: #444;
  background: url("/_assets/imgs/bg.jpg") fixed 50% 0/cover;
  width: 100%; }

body.jp {
  font-family: BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif; }

body.tw {
  font-family: "Microsoft Yahei","PingHei" ,sans-serif; }

body.cn {
  font-family: "Microsoft Yahei","PingHei" ,sans-serif; }

body.ko {
  font-family: "Malgun Gothic","Yoon Gothic",sans-serif; }

a {
  color: #333; }
  a img {
    -webkit-transition: all .5s;
    transition: all .5s;
    opacity: 1; }
  a:hover {
    color: #333; }
    a:hover img {
      opacity: .6; }

.link-fill {
  border: 1px solid #444;
  background: #444;
  padding: .9rem 2rem;
  color: #fff !important;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s; }
  .link-fill:hover {
    background: #fff;
    color: #444 !important; }
  .link-fill i {
    font-size: 120%;
    padding-right: .3rem; }

.link-border {
  border: 1px solid #444;
  padding: .9rem 2rem;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  color: #444 !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s; }
  .link-border:hover {
    background: #444;
    color: #fff !important; }
  .link-border i {
    font-size: 120%;
    padding-left: .3rem; }

.link-border-w {
  border-color: #fff;
  color: #fff !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all 0.5s; }
  .link-border-w:hover {
    background: #fff;
    color: #444 !important; }
  .link-border-w i {
    font-size: 120%;
    padding-left: .3rem; }

.to-page-top {
  z-index: 110;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
  .to-page-top a {
    text-decoration: none;
    display: block;
    font-size: 1.9rem;
    text-align: center;
    padding: 8px;
    color: #555; }

#headbar {
  z-index: 2000;
  position: absolute;
  background: #068cd2;
  color: #fff;
  width: 100%;
  min-height: 30px; }
  @media (min-width: 768px) {
    #headbar {
      top: 0; }
      #headbar .marquee {
        display: none; }
      #headbar p {
        font-size: .9rem;
        padding: .2rem;
        margin-bottom: 0; } }
  @media (max-width: 767px) {
    #headbar {
      top: 30px; }
      #headbar .marquee {
        height: 30px;
        padding: .3rem; }
        #headbar .marquee div {
          font-size: .9rem; }
      #headbar p {
        display: none; } }

#nav-lang {
  z-index: 10000;
  position: absolute;
  margin: 0;
  padding: 0; }
  #nav-lang li a {
    margin: 0 .25rem .4rem;
    color: #444;
    padding: 0 .1rem .1rem; }
    #nav-lang li a:hover {
      text-decoration: none; }
  @media (min-width: 768px) {
    #nav-lang {
      top: 50px;
      right: 12rem; }
      #nav-lang li {
        font-size: .9rem; }
        #nav-lang li a {
          border-bottom: 1px solid #fff; }
          #nav-lang li a:hover {
            border-bottom-color: #444; } }
  @media (max-width: 767px) {
    #nav-lang {
      top: 0;
      width: 100%;
      text-align: center;
      background: #000;
      height: 30px; }
      #nav-lang li {
        font-size: .75rem; }
        #nav-lang li a {
          color: #fff;
          padding: 6px 5px 0;
          display: inline-block;
          border-bottom: 1px solid #000; }
          #nav-lang li a:hover {
            border-bottom-color: #fff; } }

@media (min-width: 768px) {
  body.jp #lang-jp, body.en #lang-en, body.tw #lang-tw, body.cn #lang-cn, body.ko #lang-ko {
    border-bottom-color: #444; } }

.navbar {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  top: 30px;
  background: #fff;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1); }
  @media (max-width: 480px) {
    .navbar {
      padding: .5rem; } }
  @media (min-width: 1200px) {
    .navbar .navbar-brand img {
      height: 84px; } }
  @media (max-width: 1199px) {
    .navbar .navbar-brand img {
      height: 70px; } }
  @media (max-width: 767px) {
    .navbar .navbar-brand {
      display: block;
      margin: 0 auto;
      padding-right: 50px;
      max-width: 400px; }
      .navbar .navbar-brand img {
        width: 100%;
        height: auto; } }
  @media (max-width: 480px) {
    .navbar .navbar-brand {
      max-width: 320px; } }
  @media (max-width: 400px) {
    .navbar .navbar-brand {
      max-width: 220px; } }
  @media (max-width: 767px) {
    .navbar {
      top: 60px;
      flex-direction: row-reverse; } }

#navbars {
  align-items: flex-end;
  flex-direction: column-reverse; }
  @media (min-width: 768px) {
    #navbars .navbar-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; } }
  @media (min-width: 768px) and (max-width: 1129px) {
    #navbars .navbar-nav {
      font-size: 90%; } }
  #navbars a {
    color: #068cd2;
    border-bottom: 1px solid #fff; }
    @media (min-width: 768px) {
      #navbars a {
        padding: .5rem 0 .1rem;
        margin: 0 .5rem .4rem; } }
    #navbars a:hover {
      border-bottom-color: #068cd2; }
  #navbars li.dropdown a:hover {
    border-color: #fff; }
  #navbars .show .dropdown-menu {
    display: block;
    right: 0;
    left: auto;
    overflow: hidden;
    min-width: 8rem;
    padding: 0;
    margin: 0; }
    #navbars .show .dropdown-menu a {
      text-align: center;
      margin-left: 0;
      display: block; }
  #navbars li a.link-fill {
    background: #e34024;
    color: #fff;
    font-size: .8rem;
    padding: 5px 10px 7px;
    border-color: #e34024;
    margin-top: 3px; }
    #navbars li a.link-fill:hover {
      background: #fff;
      color: #e34024 !important;
      text-decoration: none; }

.home #nav-top, body.about #nav-about, body.program #nav-program, body.stay #nav-stay, body.safety #nav-safety, body.mikatagoko #nav-mikatagoko, body.faq #nav-faq {
  border-bottom: 1px solid #068cd2; }

footer {
  background: #444;
  color: #fff;
  position: relative;
  z-index: 100; }
  @media (min-width: 768px) {
    footer #askme {
      background: #078dd4; }
      footer #askme .container {
        background: url("/uploads/footer-contact-bg.jpg") no-repeat center bottom/cover;
        padding-bottom: 45vw !important; } }
    @media (min-width: 768px) and (min-width: 920px) {
      footer #askme .container {
        padding-bottom: 39vw !important; } }
    @media (min-width: 768px) and (min-width: 1200px) {
      footer #askme .container {
        padding-bottom: 24vw !important; } }
  @media (max-width: 767px) {
    footer #askme {
      background: #078dd4 url("/uploads/footer-contact-bg.jpg") no-repeat center bottom/contain;
      padding-bottom: 67.22vw !important; } }
  footer #askme p.target span {
    color: #666;
    background: #fff;
    padding: .5rem .8rem;
    margin: .3rem;
    border-radius: 5px;
    display: inline-block; }
  footer #askme p span, footer #askme p a {
    margin: .3rem; }
  footer #social .fb-container {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    max-width: 100%; }
    footer #social .fb-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  footer #contact p {
    line-height: 1.8; }
    footer #contact p a {
      color: #fff;
      display: inline-block;
      margin-right: 2rem;
      padding: 0;
      margin-bottom: .8rem; }
  footer #contact h2 {
    line-height: 1.5; }
  footer #letsgowakasa a {
    background: #fff;
    display: inline-block; }

.bg-top {
  background: url(/_assets/imgs/bg.jpg);
  background-size: cover; }

.bg-bottom {
  background: url(/_assets/imgs/bg.jpg) bottom;
  background-size: cover; }

#headline {
  position: relative;
  z-index: 100; }
  @media (min-width: 768px) {
    #headline {
      padding-bottom: 50px; } }

.slick-arrow {
  z-index: 1000;
  color: #fff;
  top: 40%; }
  .slick-arrow::before {
    font-family: FontAwesome;
    font-size: 3.1rem; }

.slick-prev {
  left: 10px; }
  .slick-prev::before {
    left: 0;
    content: '\f104'; }

.slick-next {
  right: 10px; }
  .slick-next::before {
    left: 0;
    content: '\f105'; }

@media (max-width: 767px) {
  .pt-5, .py-5 {
    padding-top: 2rem !important; } }

.about-head {
  background: url("/uploads/about-head-bg.png") no-repeat center bottom; }
  @media (max-width: 768px) {
    .about-head {
      background-size: 100%; }
      .about-head p br {
        display: none; } }

.contents a {
  color: #068cd2;
  text-decoration: underline; }

.contents .box {
  background: rgba(255, 255, 255, 0.8); }

.contents h2 a {
  color: #444;
  text-decoration: none; }

.downloads {
  border: 2px solid #e34024;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9); }
  .downloads h2 {
    color: #e34024; }
  .downloads ul {
    padding-left: 0; }
    .downloads ul li {
      list-style: none; }
      .downloads ul li a {
        text-decoration: underline;
        color: #068cd2; }

/* home */
.home {
  background: #fff; }
  .home #feature {
    position: relative; }
    .home #feature #feature-pc {
      height: 100vh;
      position: fixed !important;
      z-index: -100; }
    .home #feature #feature-sp {
      background: #fff;
      position: absolute;
      z-index: 100;
      width: 100vw; }
    .home #feature #badge {
      width: 100vw;
      position: absolute; }
      .home #feature #badge img {
        display: block;
        position: absolute;
        top: 230px;
        right: 40px;
        z-index: 101;
        width: 270px; }
      @media (min-width: 768px) {
        .home #feature #badge {
          height: 100vh; } }
      @media (max-width: 767px) {
        .home #feature #badge {
          height: 136.3vw; }
          .home #feature #badge img {
            top: 40px;
            right: 15px;
            width: 160px; } }
    @media (max-width: 767px) {
      .home #feature {
        height: 136.3vw; } }
  .home .contents {
    position: relative;
    z-index: 100; }
    @media (min-width: 768px) {
      .home .contents {
        padding-top: 100vh; } }
    .home .contents .pickup img {
      width: 100%; }
    .home .contents #about {
      background: url(/_assets/imgs/bg.jpg) top/cover; }
    .home .contents #program {
      background: #fff; }
      .home .contents #program .pickup {
        width: 25%; }
      .home .contents #program .slick-list a {
        display: block;
        color: #444;
        text-decoration: none; }
        @media (max-width: 768px) {
          .home .contents #program .slick-list a {
            font-size: .85rem; } }
    .home .contents #mikatagoko {
      background: url(/uploads/home-mikatagoko-bg.jpg) no-repeat/cover;
      color: #fff;
      position: relative; }
      .home .contents #mikatagoko::before {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: ' '; }
      .home .contents #mikatagoko .cover-bg {
        position: absolute;
        width: 100%;
        text-align: center; }
  .home #downloads {
    position: relative;
    z-index: 100;
    background: url(/_assets/imgs/bg.jpg) bottom/cover; }

/* about */
body.about #headline {
  padding-bottom: 30px; }
  body.about #headline #headline-image {
    background: url("/uploads/about-headline.jpg") no-repeat center top/cover;
    height: 47.361vw; }

body.about p.lead {
  font-size: 1.5rem; }

body.about #point h2 {
  color: #e34024;
  font-size: 3rem; }

body.about #point img {
  margin: 0 auto;
  display: block;
  border-radius: 3px; }

body.about #history .award {
  background: #e34024;
  color: #fff; }
  body.about #history .award .medal {
    align-self: center; }

body.about #history ul {
  padding-left: 0; }
  body.about #history ul li {
    list-style: none; }
    body.about #history ul li a {
      text-decoration: underline; }

body.about #staff img {
  width: 100%; }

.headline {
  background-repeat: no-repeat;
  color: #111;
  box-sizing: border-box;
  padding-bottom: 20px; }
  .headline h1 {
    border-bottom: 7px solid #333;
    font-weight: bold;
    padding: .3rem 1rem;
    display: inline-block;
    margin: 12vw 0 5rem;
    text-shadow: 0 0 5px #fff,0 0 5px #fff,0 0 5px #fff,0 0 5px #fff, 0 0 3px #fff; }
  .headline p span {
    background: white;
    padding: .1rem; }
  @media (min-width: 768px) {
    .headline {
      background-position: center bottom;
      background-size: cover;
      height: 47.361vw; } }
  @media (max-width: 767px) {
    .headline {
      background-position: center top;
      background-size: contain; }
      .headline h1 {
        margin: 25vw 0 35vw;
        font-size: 1.6rem; } }

#headline.headline-mini {
  height: auto;
  background: url("/uploads/page-head-bg.jpg") no-repeat center center/cover; }
  @media (min-width: 768px) {
    #headline.headline-mini {
      padding: 50px 0 70px;
      margin-bottom: 3rem; } }
  @media (max-width: 767px) {
    #headline.headline-mini {
      padding: 30px 0 40px;
      margin-bottom: 2rem; } }
  #headline.headline-mini h1 {
    margin: 0; }
  #headline.headline-mini p {
    margin-top: 2rem; }

/* program */
.program #headline {
  background-image: url("/uploads/program-head-bg.jpg"); }

.program #program-index img {
  width: 100%; }

.program #program-index h3 span {
  border-radius: 5px;
  vertical-align: middle;
  color: #fff;
  display: inline-block; }
  .program #program-index h3 span.num {
    padding: .1rem 0;
    font-size: 1rem;
    width: 1.6rem;
    text-align: center;
    margin-right: .3rem; }
  .program #program-index h3 span.num1 {
    background: #369; }
  .program #program-index h3 span.num2 {
    background: #659833; }
  .program #program-index h3 span.num3 {
    background: #7A4C1E; }
  .program #program-index h3 span.num4 {
    background: #DF7C19; }
  .program #program-index h3 span.pickup {
    padding: .2rem .3rem;
    margin-left: .5rem;
    font-size: .8rem;
    background: #e34024; }

.program #program-index p span.note {
  font-size: .9rem; }

.program #program-index p.thanks {
  font-size: .8rem;
  text-align: right;
  color: #666; }

.program #plan table {
  width: 100%;
  background: #fff;
  font-size: 95%; }
  .program #plan table th {
    font-weight: normal;
    width: 4.2rem;
    text-align: right;
    padding: .15rem .8rem .3rem 0; }
  .program #plan table td {
    padding: .15rem .5rem .3rem; }

/* stay */
.stay #headline {
  background-image: url("/uploads/stay-head-bg.jpg"); }

.stay .link-border i {
  padding: 0; }

.stay #yado-index h2 {
  color: #e34024; }

.stay .yado p span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; }

.stay .yado table th {
  padding: .75rem 0;
  width: 4rem;
  font-size: .9rem; }

.stay .yado table td {
  padding: .75rem 0 .75rem .5rem;
  font-size: .9rem; }

/* faq */
.faq dt {
  font-size: 1.6rem;
  cursor: pointer; }

.faq dd {
  display: none; }
  .faq dd p {
    padding: .8rem 1rem;
    margin-top: .5rem;
    border: 1px dashed #666;
    font-size: .9rem; }

/* mikatagoko */
.mikatagoko #headline {
  background-image: url("/uploads/mikatagoko-head-bg.jpg"); }
  @media (min-width: 768px) {
    .mikatagoko #headline {
      padding-top: 4.5vw; } }

.mikatagoko #access p .note {
  font-size: .8rem;
  display: inline-block;
  margin-bottom: .3rem; }

.mikatagoko #gmap {
  width: 100%;
  height: 100%;
  padding-bottom: 80%;
  max-width: 100%; }

/* contact */
.contact #flow .arrow {
  font-size: 2rem;
  color: #ccc; }

/* 英語 */
@media (min-width: 768px) {
  .en #nav-lang {
    right: 14rem; } }

@media (min-width: 768px) and (max-width: 1266px) {
  .en #nav-lang {
    margin-top: -5px; } }

@media (min-width: 768px) {
  .en #headbar {
    height: auto;
    min-height: 30px; }
    .en #headbar p {
      font-size: .7rem;
      padding: .4rem .2rem 0; } }

@media (max-width: 767px) {
  .en #headbar .marquee {
    padding-top: .5rem; }
    .en #headbar .marquee div {
      font-size: .7rem; } }

@media (min-width: 768px) {
  .en #navbars .navbar-nav li {
    font-size: .8rem; }
  .en #navbars .navbar-nav:nth-child(1) a {
    padding: .5rem 0 0;
    margin: 0 .5rem; } }

/* tw */
@media (min-width: 768px) {
  .tw #nav-lang {
    right: 9rem; } }

/* cn */
@media (min-width: 768px) {
  .cn #nav-lang {
    right: 8rem; } }

/* ko */
@media (min-width: 768px) {
  .ko #nav-lang {
    right: 8rem; } }
